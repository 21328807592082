import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Draggable from 'react-draggable';

let isDragging = false;

const handleDrag = () => {
    isDragging = true;
}

const handleStop = () => {
    setTimeout(() => { isDragging = false; }, 50)
}

const handleClick = (nav) => {
    if (!isDragging) {
        navigate(nav)
    }
}

const ProjectPreview = ({ title, image, url }) => {
    return (
        <Draggable
            onDrag={handleDrag}
            onStop={handleStop}><div className='project-preview-image-container'>
                <div className='project-preview-container' onClick={() => handleClick(url)} onTouchStart={() => handleClick(url)}>
                    <img src={image} /></div>
                {/*<h1 className='project-preview-title'>{title}</h1>*/}
            </div></Draggable>
    );
};

export default ProjectPreview;
import React from 'react';
import '../styles/dock.css';
import Finder from '../assets/Finder.png'
import Trash from '../assets/bin.png'
import { navigate } from 'gatsby';

const Dock = () => {
    return (
        <div className='dock'>
            <div className='dock-left'>
                <div className='dock-item'>
                    <img src={Finder} className='dock-icon' onClick={() => navigate('/')} />
                </div>
            </div>
            <div className='dock-center'>
                <p className='scrolling-text worked-with'>trusted by:</p>
                <span className='divider' />
                <p className='scrolling-text marquee'><span>Apple     Ksubi     JD Sport     Entropico     Andpeople     JodyJust     Footlocker     Petespace     Fordham Media     EMI     SONY     Street University     POTIRAKIS     Whitehouse Institute of Design     Commonhouse Studio     opnsrc     Laneway Festival     Bodega Studio     Parramatta Council     PrettyFilms     Duty Free     Bloody Dior     Jason Denison     Zac Fox     Odunsi     KIAN     Felix Dame     Destroy Lonely      ONEFOUR     Paris Texas      Becca Hatch     Keiynan Lonsdale     Maxine Champ      LEE     Breakfast Road     Sollyy     Friday*     Zion Garcia     Dylan Atlantis     Solo Chulo     Manu Crooks      Kwame     Blessed     Cult Shotta      KENFO     baschoe     SANA     Pania     everyone</span></p>
                <span className='divider' />
            </div>
            <div className='dock-right'>
                <div className='dock-item'>
                    <img src={Trash} className='dock-icon' />
                </div>
            </div>
        </div>
    );
};

export default Dock;
import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/portfolio.css'
import Draggable from "react-draggable"
import Dock from '../components/Dock';

const portfolio = ({ data }) => {
  const posts = get(this, 'props.data.allContentfulProject.nodes')
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="black" />
        <title>Portfolio - HYSG</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <MenuBar />
      <div className='portfolio-container'>
        <Draggable><h1 className='title'>portfolio</h1></Draggable>
        <div className='project-previews'>
          {
            data.allContentfulProject.nodes.map(node => (
              <ProjectPreview title={node.title} image={node.mainImage.resize.src} url={node.url} />
            ))
          }
        </div>
      </div>
      <span className='dock-spacer' />
      <Dock />
    </main>
  );
};

export const query = graphql`
query PortfolioQuery {
  allContentfulProject(sort: { fields: [updatedAt], order: DESC }) {
      nodes {
        title
        url
        mainImage {
          url
          resize(quality: 60, height: 400, format: WEBP) {
            src
          }
        }
        updatedAt
      }
    }
}
`

export default portfolio;